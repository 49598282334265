@import '../../styles/variables';

// empty placeholder
.empty {
  display: grid;
  place-content: center;
}

.mt {
  margin-top: 5rem;
}

.empty_container {
  display: grid;
  grid-auto-rows: max-content;
  gap: 1.5rem;
  justify-items: center;

  p {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: $blueb4;
  }

  img {
    margin-top: 3rem;
  }
}

// empty placeholder

// addworks drawer
.main_awd {
  text-align: center;
  //margin-top: 20px;
  // display: grid;
  // grid-template-rows: auto calc(100vh - 100px);
  /* ::-webkit-scrollbar {
      width: 0;
  } */
  h1 {
    font-weight: 500;
    font-size: 18px;
    color: $blueb4;
    box-shadow: inset 0px -1px 0px #d1d8fa;
    padding: 1rem;
  }
}

.awd_container {
  display: flex;
  flex-direction: column;
}

.awd_description {
  h4 {
    // margin-top: 1rem;
    color: #f97316;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 143%;
    color: #2B3648;
    margin: 0;
  }
}

.awd_loader {
  width: 100%;
  margin: 20px 0;
  overflow: auto;
  min-height: 300px;
  max-height: calc(100vh - 220px);
  border-radius: 8px;
  background-color: rgb(246 247 251);
  place-content: center;
}

.awd_loader_error {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #f46b6b;
  }
}

.awd_loader_success {
  p {
    font-size: 14px;
    color: #5c6e8c;
  }
}

.awd_download {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  p {
    font-size: 13px;
    font-weight: 500;
    color: #2b3648;
  }

  svg {
    fill: $blueb4;
  }
}

.warning_modal {
  text-align: center;
  padding: 1rem;
  display: grid;
  gap: 1rem;

  h4 {
    color: $blueb4;
  }

  p {
    font-size: 14px;
    color: #2b3648;
  }
}

.warning_buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

// AddWorksDrawer End

// ErrorsList
.errors_close {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 2;
}

.errors_list {
  margin: 0 1rem;
  width: 22rem;
  overflow: auto;

  height: 100%;

  li {
    padding: 0.8rem 0;
    border-bottom: 1px solid #edf0fd;
  }
}

.errors_item {
  list-style: none;
  margin-left: none;
  display: grid;
  gap: 0.5rem;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.errors_type {
  h5 {
    margin-top: 0.1rem;
    color: $blueb4;
    font-size: 14px;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }

  p {
    margin-top: 0.1rem;
    font-size: 12px;
    color: $darkgray48;
  }
}

.errors_message {
  font-size: 14px;
  color: $darkgray48;
  text-align: left;
}

// ErrorsList
